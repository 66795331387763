import React, { FC, Ref, useState } from 'react'
import CustomInput from 'components/creative-tim/CustomInput/CustomInput.js'
import { InputAdornment, useTheme } from '@material-ui/core'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import NumberFormat from 'react-number-format'
import { green, red } from '@material-ui/core/colors'

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

function NumberFormatCustom(props: NumberFormatCustomProps): React.ReactNode {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      allowNegative={false}
      decimalScale={2}
    />
  )
}

interface IProps {
  label: string
  required?: boolean
  initialValue?: number
  showOptionalLabel?: boolean
  optionalLabelSuffix?: string
  name?: string
  inputRef?: Ref<any>
  onChange?: (value: number | undefined) => void
}

const Input: FC<IProps> = ({
  required: Required,
  label: Label,
  initialValue,
  showOptionalLabel,
  optionalLabelSuffix,
  onChange,
  ...rest
}) => {
  const theme = useTheme()

  const oLabelSuffix = optionalLabelSuffix ? ` ${optionalLabelSuffix}` : ''
  const [inputValue, setInputValue] = useState(initialValue)
  return (
    <CustomInput
      white={theme.palette.type === 'dark'}
      labelText={
        !Required && showOptionalLabel
          ? `${Label} (Optional${oLabelSuffix})`
          : Label
      }
      formControlProps={{
        fullWidth: true,
      }}
      error={Required && !inputValue}
      success={Required && !!inputValue}
      inputProps={{
        endAdornment: Required ? (
          <InputAdornment data-testid="currency-input-required" position="end">
            {inputValue ? (
              <CheckCircleOutlinedIcon style={{ color: green[500] }} />
            ) : (
              <CancelOutlinedIcon style={{ color: red[500] }} />
            )}
          </InputAdornment>
        ) : undefined,
        startAdornment: inputValue ? (
          <InputAdornment data-testid="currency-symbol" position="start">
            £
          </InputAdornment>
        ) : null,
        inputComponent: NumberFormatCustom,
        inputProps: {
          'data-testid': 'currency-input',
          inputMode: 'decimal',
        },
        onChange: (
          arg: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          const value = arg.target.value ? +arg.target.value : undefined
          setInputValue(value)
          onChange && onChange(value)
        },
        value: inputValue,
        ...rest,
      }}
    />
  )
}

export default Input
