import React, { FC, Ref, useEffect, useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { currencyFormatter } from 'libs/formatters'
import useSessionStorage from 'hooks/useSessionStorage'
import * as Sentry from '@sentry/react'
import fetch from 'libs/fetchWrapper'
import { PaymentFees, paymentSchema } from 'components/sections/Models'

export type IProps = {
  name?: string
  required?: boolean
  initialValue?: PaymentFees | null
  inputRef?: Ref<any>
  fetchOnLoad?: boolean
  onChange?: (value: PaymentFees | null) => void
}

const DropDown: FC<IProps> = ({
  required: Required,
  initialValue,
  name,
  inputRef,
  fetchOnLoad = true,
  onChange,
}) => {
  const [isError, setIsError] = useState(false)
  const [paymentProviders, setPaymentProviders] = useSessionStorage<
    PaymentFees[]
  >('paymentProviders', [])
  const [paymentsLoaded, setPaymentsLoaded] = useState(false)
  const [paymentSelected, setPaymentSelected] = useState<
    PaymentFees | null | undefined
  >(initialValue)
  const [inputValue, setInputValue] = useState(initialValue?.Name)
  const [fetchData, setFetchData] = useState(fetchOnLoad)

  useEffect(() => {
    if (!fetchData) return
    if (paymentProviders.length > 0) {
      setPaymentsLoaded(true)
      return
    }
    const abortController = new AbortController()

    fetch(`${process.env.GATSBY_API_URL}/paymentProviders`, {
      signal: abortController.signal,
    })
      .then(res => res.json())
      .then((result: PaymentFees[]) => {
        result.forEach(r => paymentSchema.parse(r))
        if (!abortController.signal.aborted) {
          setPaymentProviders(result)
          setPaymentsLoaded(true)
          setIsError(false)
        }
      })
      .catch(reason => {
        Sentry.captureException(reason)
        if (!abortController.signal.aborted) setIsError(true)
      })

    return () => {
      abortController.abort()
    }
  }, [fetchData, paymentProviders.length, setPaymentProviders])

  return (
    <>
      <Autocomplete
        id="payment-autocomplete"
        data-testid="payment-autocomplete"
        value={paymentSelected}
        onOpen={() => setFetchData(true)}
        inputValue={inputValue}
        onInputChange={(_event, value) => setInputValue(value)}
        loading={!paymentsLoaded}
        options={paymentProviders}
        getOptionLabel={option => option.Name}
        getOptionSelected={option => option.Name === paymentSelected?.Name}
        multiple={false}
        onChange={(_event, value) => {
          setPaymentSelected(value)
          onChange && onChange(value)
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Payment Method"
            variant="outlined"
            error={isError || (Required && !paymentSelected)}
            helperText={
              Required && !paymentSelected
                ? 'Required'
                : paymentSelected
                ? `${
                    paymentSelected.Fee.Percentage
                  }% + ${currencyFormatter.format(
                    paymentSelected.Fee.FlatFee || 0
                  )}`
                : undefined
            }
            data-testid="payment-dropdown"
            id="payment-dropdown"
            name={name}
            inputRef={inputRef}
          />
        )}
      ></Autocomplete>
    </>
  )
}

export default DropDown
